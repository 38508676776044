import { AxiosRequestConfig } from 'axios';
import { IAuthData, ICar, IDriver, TOrderStatus, TParam } from './redux/dataSlice';

export interface IParamResponse {
	ID: string;
	name: string;
}
export interface IApiResponse {
	Success: string;
	Error: string;
	ID?: string;
}
export interface IApiGetMessagesResponse extends IApiResponse {
	Message?: string;
}
export interface IApiAuthResponse extends IApiResponse {
	ApiKey?: string;
}
export interface IApiOrdersResponse extends IApiResponse {
	orders?: IApiOrderResponse[];
}
export interface IApiCarsResponse extends IApiResponse {
	Cars?: IParamResponse[];
}
export interface IApiDriversResponse extends IApiResponse {
	Drivers?: IParamResponse[];
}
export interface IApiOrdersFileResponse {
	ID: string;
	Name: string;
	Extension: string;
	Type: string;
	TypeID: string;
	Editable: string;
}
export interface IApiOrderResponse extends IApiResponse {
	Number: string;
	Date: string;
	Status: TOrderStatus;
	DateP: string;
	DateV: string;
	NumberKTK: string;
	TypeKTK: string;
	Address: string;
	Terminal?: string;
	City?: string;
	TerminalP: string;
	TerminalPAddress: string;
	TerminalV: string;
	TerminalVAddress: string;
	Fare: string;
	Driver: string;
	Car: string;
	Weight: string;
	Comment: string;
	Files: IApiOrdersFileResponse[];
}
export interface IApiCarResponse extends IApiResponse {
	Name: string;
	GRZ: string;
	Marka: string;
	PGRZ: string;
	PMarka: string;
}
export interface IApiDriverResponse extends IApiResponse {
	Fio: string;
	Familia: string;
	Imya: string;
	Otch: string;
	DateR: string;
	PassSeria: string;
	PassNomer: string;
	PassDate: string;
	PassOVD: string;
	PassCodeOVD: string;
	VUSeria: string;
	VUNomer: string;
	VUDate: string;
}
export interface IApiParamsResponse extends IApiResponse {
	Params?: IParamResponse[];
	Cars?: IParamResponse[];
	Drivers?: IParamResponse[];
}
export interface IApiOrderFileDownloadResponse extends IApiResponse {
	Data: string;
}
export interface IApiOrderFileParams {
	orderId?: string;
	fileId: string;
	name?: string;
	ext?: string;
	data?: string | ArrayBuffer | null;
	typeId?: string;
	typeName?: string;
}
export interface IApiOrderChangeParams {
	orderId: string;
	param: 'SetFare' | 'InWork' | 'Reject' | 'Finish';
	fare?: string;
	driverId?: string;
	carId?: string;
	reason?: string;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const setToken = (token: string) => localStorage.setItem('token', token);
export const getToken = () => localStorage.getItem('token');
export const removeToken = () => localStorage.removeItem('token');

export const ordersApiConfig = (): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/GetOrders',
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const carsApiConfig = (): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/GetCars',
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const driversApiConfig = (): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/GetDrivers',
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const changeOrderApiConfig = (params: IApiOrderChangeParams): AxiosRequestConfig => ({
	method: 'post',
	url: apiUrl + '/Order',
	params: {
		...(params.param === 'SetFare'
			? { Fare: params.fare }
			: params.param === 'InWork'
			? { Driver: params.driverId, Car: params.carId }
			: params.param === 'Reject'
			? { Reason: params.reason }
			: {}),
		Param: params.param,
		ID: params.orderId,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const changeCarApiConfig = (params: ICar): AxiosRequestConfig => ({
	method: 'post',
	url: apiUrl + '/Car',
	params: {
		...(params.id ? { ID: params.id } : {}),
		GRZ: params.grz,
		Marka: params.model,
		PGRZ: params.pGrz,
		PMarka: params.pModel,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});
export const changeDriverApiConfig = (params: IDriver): AxiosRequestConfig => ({
	method: 'post',
	url: apiUrl + '/Driver',
	params: {
		...(params.id ? { ID: params.id } : {}),
		Familia: params.surname,
		Imya: params.firstName,
		Otch: params.patronymic,
		DateR: params.birthDay,
		PassSeria: params.passSeries,
		PassNomer: params.passNumber,
		PassDate: params.passDate,
		PassOVD: params.passOVD,
		PassCodeOVD: params.passCodeOVD,
		VUSeria: params.dlSeries,
		VUNomer: params.dlNumber,
		VUDate: params.dlDate,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});
export const deleteCarApiConfig = (id: string): AxiosRequestConfig => ({
	method: 'delete',
	url: apiUrl + '/Car',
	params: {
		ID: id,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});
export const deleteDriverApiConfig = (id: string): AxiosRequestConfig => ({
	method: 'delete',
	url: apiUrl + '/Driver',
	params: {
		ID: id,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const orderFileDownloadApiConfig = (param: IApiOrderFileParams): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/File',
	params: {
		ID: param.orderId,
		IDFile: param.fileId,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const orderFileDeleteApiConfig = (param: IApiOrderFileParams): AxiosRequestConfig => ({
	method: 'delete',
	url: apiUrl + '/File',
	params: {
		ID: param.orderId,
		IDFile: param.fileId,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const orderFileUploadApiConfig = (param: IApiOrderFileParams): AxiosRequestConfig => ({
	method: 'post',
	url: apiUrl + '/File',
	maxBodyLength: Infinity,
	params: {
		ID: param.orderId,
		IDFileType: param.typeId,
		Name: param.name,
		Extension: param.ext,
	},
	data: param.data,
	headers: {
		'Content-Type': 'text/plain',
		Auth: `Token ${getToken()}`,
	},
});

export const paramsApiConfig = (param: TParam): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/GetParams',
	params: {
		Param: param,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const orderApiConfig = (id: string): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/Order',
	params: {
		ID: id,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const carApiConfig = (id: string): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/Car',
	params: {
		ID: id,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const driverApiConfig = (id: string): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/Driver',
	params: {
		ID: id,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const getMessagesApiConfig = (orderId: string): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/Message',
	params: {
		ID: orderId,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const sendMessageApiConfig = (orderId: string, message: string): AxiosRequestConfig => ({
	method: 'post',
	url: apiUrl + '/Message',
	params: {
		ID: orderId,
		Message: message,
	},
	headers: {
		Auth: `Token ${getToken()}`,
	},
});

export const authByDataApiConfig = (authData: IAuthData): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/GetAuth',
	headers: { Auth: `Basic ${authData.login}:${authData.password}` },
});

export const authByTokenApiConfig = (token: string): AxiosRequestConfig => ({
	method: 'get',
	url: apiUrl + '/GetAuth',
	headers: { Auth: `Token ${token}` },
});
