import { FileAddOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Avatar, Button, Collapse, List, Modal, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { RootState } from '../../redux/store';
import { uploadOrderFile } from '../../saga/getDataSaga';
import DeleteButton from './DeleteButton';
import DownloadButton from './DownloadButton';
import { setMessage } from '../../redux/dataSlice';

const getExtImgSrc = (ext: string) => {
	if (['csv', 'pdf'].indexOf(ext.toLowerCase()) > -1) return `/img/${ext}.svg`;
	if (['doc', 'docx'].indexOf(ext.toLowerCase()) > -1) return `/img/doc.svg`;
	if (['xls', 'xlsx'].indexOf(ext.toLowerCase()) > -1) return `/img/xls.svg`;
	if (['jpg', 'jpeg', 'png', 'bmp', 'gif', 'webp'].indexOf(ext.toLowerCase()) > -1) return `/img/img.svg`;
	if (['html', 'htm', 'mhtml'].indexOf(ext.toLowerCase()) > -1) return `/img/html.svg`;
	return '/img/file.svg';
};
const getRandom = () => `${crypto?.randomUUID ? crypto.randomUUID() : Math.floor(Math.random() * Number.MAX_VALUE)}`;

function FileList() {
	const [uploadFileList, setUploadFileList] = useState<FileList | null>(null);
	const [selectedTypeId, setSelectedTypeId] = useState<string | null>(null);
	const order = useSelector((state: RootState) => state.data.order);
	const params = useSelector((state: RootState) => state.data.params);
	const dispatch = useDispatch();

	const uploadFiles = useCallback((fileList: FileList, typeId: string) => {
		Array.from(fileList).forEach((file) => {
			const getBase64 = (file: any) => {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function () {
					const nameArr = file.name.split('.');
					const ext = nameArr.length === 1 ? '' : nameArr.pop() || '';
					const name = nameArr.join('.');
					dispatch(
						uploadOrderFile({
							fileId: getRandom(),
							typeId,
							typeName: params.find((i) => i.type === 'FileTypes')?.data?.find((i) => i.id === typeId)
								?.name,
							orderId: order?.id || '',
							name,
							ext,
							data: reader.result,
						})
					);
				};
				reader.onerror = function (error) {
					dispatch(setMessage({ type: 'error', message: 'Ошибка загрузки файла' }));
				};
			};
			getBase64(file);
		});
	}, []);

	const handleTypeSelect = () => {
		if (selectedTypeId) {
			if (uploadFileList) uploadFiles(uploadFileList, selectedTypeId);
			setUploadFileList(null);
		} else {
			dispatch(setMessage({ type: 'warning', message: 'Выберите тип файла' }));
		}
	};

	const handleTypeCancel = () => {
		setUploadFileList(null);
	};

	const selectFiles = useCallback(() => {
		const input = document.createElement('input');
		input.type = 'file';
		input.multiple = true;
		input.onchange = (e: any) => {
			setUploadFileList(e.target.files);
		};
		input.click();
	}, []);

	return (
		<>
			<Modal
				title={`Выбор типа файл${uploadFileList && uploadFileList.length > 1 ? 'ов' : 'а'}`}
				open={!!uploadFileList}
				onCancel={handleTypeCancel}
				onOk={handleTypeSelect}
				footer={[
					<Button key="cancel" onClick={handleTypeCancel}>
						Отмена
					</Button>,
					<Button key="select" type="primary" onClick={handleTypeSelect}>
						Выбрать
					</Button>,
				]}
			>
				<Select
					showSearch
					placeholder={`Выбеите тип файл${uploadFileList && uploadFileList.length > 1 ? 'ов' : 'а'}`}
					optionFilterProp="label"
					style={{ width: '100%' }}
					defaultValue={selectedTypeId}
					onChange={(v) => setSelectedTypeId(v)}
					options={
						params
							.find((i) => i.type === 'FileTypes')
							?.data?.map((i) => ({ value: i.id, label: i.name })) || []
					}
				/>
			</Modal>
			<Collapse
				items={[
					{
						key: 'files',
						label: 'Список файлов',
						children: (
							<>
								<List
									itemLayout="horizontal"
									dataSource={order?.files || []}
									renderItem={(item) => (
										<List.Item
											actions={
												item.hasUploadError === 'true'
													? [<WarningOutlined />]
													: item.isUploading === 'true'
													? [<LoadingOutlined spin />]
													: [
															<DownloadButton orderId={order?.id || ''} file={item} />,
															<DeleteButton orderId={order?.id || ''} file={item} />,
													  ]
											}
										>
											<List.Item.Meta
												avatar={
													<Avatar
														shape="square"
														style={{ borderRadius: 0 }}
														src={getExtImgSrc(item.extension)}
													/>
												}
												title={item.type}
												description={`${item.name}.${item.extension}`}
											/>
										</List.Item>
									)}
								/>
								<Button
									className="sbutton"
									onClick={selectFiles}
									icon={<FileAddOutlined />}
									type="primary"
								>
									Добавить файл
								</Button>
							</>
						),
					},
				]}
				defaultActiveKey={'files'}
			/>
		</>
	);
}

export default FileList;
