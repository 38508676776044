import { PlusOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { getCars, getDrivers } from '../../saga/getDataSaga';
import BreadCrumb from '../ui/BreadCrumb';
import Spiner from '../ui/Spiner';

function ListPage({ src, path, title }: { src: 'cars' | 'drivers'; path: string; title: string }) {
	const list = useSelector((state: RootState) => state.data[src]);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		switch (src) {
			case 'cars': {
				dispatch(getCars());
				break;
			}
			case 'drivers': {
				dispatch(getDrivers());
				break;
			}
		}
	}, [dispatch, src]);

	return (
		<div className="list page">
			<BreadCrumb
				items={[
					{
						path: `/${path}`,
						title: <span>{title}</span>,
					},
				]}
			/>
			{!list ? (
				<Spiner tip="Загрузка" />
			) : (
				<>
					<List
						bordered
						dataSource={list}
						renderItem={(item) => (
							<List.Item className="item" onClick={() => navigate(`/${path}/${item.id}`)}>
								{item.name}
							</List.Item>
						)}
					/>
					<Button
						icon={<PlusOutlined />}
						type="primary"
						className="sbutton"
						onClick={() => navigate(`/${path}/new`)}
					>
						Добавить
					</Button>
				</>
			)}
		</div>
	);
}

export default ListPage;
