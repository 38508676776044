import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import BreadCrumb from '../ui/BreadCrumb';
import { useEffect } from 'react';
import { getOrders } from '../../saga/getDataSaga';
import Spiner from '../ui/Spiner';
import OrderCard from '../ui/OrderCard';

function OrdersList() {
	const orders = useSelector((state: RootState) => state.data.orders);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOrders());
	}, [dispatch]);

	return (
		<div className="orderslist page">
			<BreadCrumb
				items={[
					{
						path: '/orders',
						title: <span>Заявки</span>,
					},
				]}
			/>
			{!orders ? (
				<Spiner tip="Загрузка" />
			) : (
				<>
					{orders.map((order) => (
						<OrderCard key={order.number} data={order} />
					))}
				</>
			)}
		</div>
	);
}

export default OrdersList;
