import { useParams } from 'react-router-dom';
import BreadCrumb from '../ui/BreadCrumb';
import { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { changeOrder, getOrder } from '../../saga/getDataSaga';
import { Button, FormInstance, Modal } from 'antd';
import Spiner from '../ui/Spiner';
import TextArea from 'antd/es/input/TextArea';
import FileList from '../ui/FileList';
import PageForm, { IPageFormData } from '../ui/PageForm';
import { IOrder, setMessage } from '../../redux/dataSlice';
import MessageBox from '../ui/MessageBox';

function Order() {
	const [rejectReason, setRejectReason] = useState<{ modalShowed: boolean; reason: string }>({
		modalShowed: false,
		reason: '',
	});
	const { id } = useParams();
	const order = useSelector((state: RootState) => state.data.order);
	const formRef = useRef<FormInstance>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOrder(id || ''));
	}, [dispatch, id]);

	useEffect(() => {
		formRef.current?.setFieldValue('fare', order?.fare);
	}, [formRef, order]);

	const toBetHandler = useCallback(() => {
		const data = formRef.current?.getFieldsValue(true);
		if (order) dispatch(changeOrder({ orderId: order.id, param: 'SetFare', fare: data.fare }));
	}, [order, dispatch]);
	const toRejectHandler = useCallback(() => {
		setRejectReason({ modalShowed: true, reason: '' });
	}, []);
	const toTakeHandler = useCallback(() => {
		if (order)
			dispatch(changeOrder({ orderId: order.id, param: 'InWork', driverId: order.driverId, carId: order.carId }));
	}, [dispatch, order]);
	const toCompleteHandler = useCallback(() => {
		if (order) dispatch(changeOrder({ orderId: order.id, param: 'Finish' }));
	}, [dispatch, order]);
	const rejectReasonCancelHandler = useCallback(() => setRejectReason({ modalShowed: false, reason: '' }), []);
	const rejectReasonOkHandler = useCallback(() => {
		if (order) {
			if (rejectReason.reason.length > 0) {
				dispatch(changeOrder({ orderId: order.id, param: 'Reject', reason: rejectReason.reason }));
				setRejectReason({ modalShowed: false, reason: '' });
			} else {
				dispatch(setMessage({ type: 'warning', message: 'Укажите причину отказа' }));
			}
		}
	}, [dispatch, order, rejectReason.reason]);

	return (
		<div className="page">
			<BreadCrumb
				items={[
					{
						path: '/orders',
						title: <span>Заявки</span>,
					},
					{
						path: `/orders/${id}`,
						title: <span>{`Заявка${order && order.number ? ` №${order.number}` : ''}`}</span>,
					},
				]}
			/>
			{!order ? (
				<Spiner tip="Загрузка" />
			) : (
				<>
					<MessageBox />
					<PageForm
						formRef={formRef}
						data={
							{
								src: order,
								items: [
									{ label: 'Дата', name: 'date', disabled: true },
									{ label: 'Статус', name: 'status', disabled: true },
									{ label: 'Номер КТК', name: 'numberKTK', disabled: true },
									{ label: 'Тип КТК', name: 'typeKTK', disabled: true },
									{ label: 'Адрес доставки', name: 'address', disabled: true },
									{ item: 'divider' },
									{ label: 'Терминал погрузки', name: 'terminalPNumber', disabled: true },
									{ label: 'Дата погрузки', name: 'dateP', disabled: true },
									{ label: 'Адрес терминала погрузки', name: 'terminalPAddress', disabled: true },
									{ item: 'divider' },
									{ label: 'Терминал сдачи', name: 'terminalVNumber', disabled: true },
									{ label: 'Дата выгрузки', name: 'dateV', disabled: true },
									{ label: 'Адрес терминала сдачи', name: 'terminalVAddress', disabled: true },
									{ item: 'divider' },
									{
										label: 'Комментарий',
										name: 'comment',
										type: 'textarea',
										disabled: true,
										params: { rows: 4 },
									},
									{ item: 'divider' },
									{ label: 'Ставка', name: 'fare', disabled: order.status !== 'Торг' },
									{ item: 'divider' },

									order.status === 'Выиграна' && {
										label: 'Водитель',
										name: 'driverName',
										disabled: true,
									},
									order.status === 'Выиграна' && {
										label: 'Транспортное средство',
										name: 'carNumber',
										disabled: true,
									},
									order.status === 'Выиграна' && { item: 'divider' },

									{ item: 'element', content: <FileList key="filelist" /> },

									order.status === 'Торг' && {
										item: 'button',
										label: 'Сделать ставку',
										onClick: toBetHandler,
									},
									order.status === 'Выиграна' && {
										item: 'button',
										label: 'Взять в работу',
										onClick: toTakeHandler,
									},
									(order.status === 'Выиграна' || order.status === 'В работе') && {
										item: 'button',
										label: 'Отказаться',
										onClick: toRejectHandler,
									},
									order.status === 'В работе' && {
										item: 'button',
										label: 'Завершить',
										onClick: toCompleteHandler,
									},
								],
							} as IPageFormData<IOrder>
						}
					/>
					<Modal
						title="Укажите причину отказа"
						open={rejectReason.modalShowed}
						onCancel={rejectReasonCancelHandler}
						onOk={rejectReasonOkHandler}
						footer={[
							<Button key="cancel" onClick={rejectReasonCancelHandler}>
								Отмена
							</Button>,
							<Button key="select" type="primary" onClick={rejectReasonOkHandler}>
								Отказаться
							</Button>,
						]}
					>
						<TextArea
							rows={4}
							placeholder="Укажите причину отказа"
							showCount
							maxLength={300}
							style={{ width: '100%', marginBottom: '15px' }}
							value={rejectReason.reason}
							onChange={(e) => setRejectReason({ reason: e.target.value, modalShowed: true })}
						/>
						<br />
					</Modal>
				</>
			)}
		</div>
	);
}

export default Order;
