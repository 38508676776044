import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { RootState } from '../../redux/store';
import Spiner from '../ui/Spiner';

function Layout() {
	const authStatus = useSelector((state: RootState) => state.data.auth.status);

	return (
		<>
			<header className="fwc">
				<div className="width">
					<Link to="/" className="logo">
						LOGO
					</Link>
				</div>
			</header>
			<div className="layout width">
				{authStatus === 'inprogress' ? (
					<div className="center">
						<Spiner tip="Авторизация" />
					</div>
				) : (
					<Outlet />
				)}
			</div>
			<footer className="fwc">
				<div className="width">Footer</div>
			</footer>
		</>
	);
}

export default Layout;
