import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/pages/Layout';
import Main from './components/pages/Main';
import OrdersList from './components/pages/OrdersList';
import Auth from './components/pages/Auth';
import PrivateRoute from './components/pages/PrivateRoute';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { authByToken } from './saga/getDataSaga';
import { setAuthStatus } from './redux/dataSlice';
import { getToken } from './apiConfigs';
import Order from './components/pages/Order';
import Car from './components/pages/Car';
import Driver from './components/pages/Driver';
import ListPage from './components/pages/ListPage';
import Messages from './components/ui/Messages';

function App() {
	const { status } = useSelector((state: RootState) => state.data.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (status !== 'authorized') {
			const token = getToken();
			if (token) {
				dispatch(authByToken(token));
			} else {
				dispatch(setAuthStatus('unauthorized'));
			}
		}
	}, []);

	return (
		<BrowserRouter>
			<div className="App fwc">
				<Messages />
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route path="auth" element={<Auth />} />
						<Route index element={<PrivateRoute component={<Main />} />} />
						<Route path="orders" element={<PrivateRoute component={<OrdersList />} />} />
						<Route path="orders/:id" element={<PrivateRoute component={<Order />} />} />
						<Route
							path="drivers"
							element={
								<PrivateRoute component={<ListPage path="drivers" title="Водители" src="drivers" />} />
							}
						/>
						<Route path="drivers/:id" element={<PrivateRoute component={<Driver />} />} />
						<Route
							path="cars"
							element={<PrivateRoute component={<ListPage path="cars" title="Машины" src="cars" />} />}
						/>
						<Route path="cars/:id" element={<PrivateRoute component={<Car />} />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Route>
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default App;
