import { createAction, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	authByDataApiConfig,
	authByTokenApiConfig,
	carApiConfig,
	carsApiConfig,
	changeCarApiConfig,
	changeDriverApiConfig,
	changeOrderApiConfig,
	deleteCarApiConfig,
	deleteDriverApiConfig,
	driverApiConfig,
	driversApiConfig,
	getMessagesApiConfig,
	IApiAuthResponse,
	IApiCarResponse,
	IApiCarsResponse,
	IApiDriverResponse,
	IApiDriversResponse,
	IApiGetMessagesResponse,
	IApiOrderChangeParams,
	IApiOrderFileDownloadResponse,
	IApiOrderFileParams,
	IApiOrderResponse,
	IApiOrdersResponse,
	IApiParamsResponse,
	IApiResponse,
	orderApiConfig,
	orderFileDeleteApiConfig,
	orderFileDownloadApiConfig,
	orderFileUploadApiConfig,
	ordersApiConfig,
	paramsApiConfig,
	removeToken,
	sendMessageApiConfig,
	setToken,
} from '../apiConfigs';
import {
	IAuthData,
	ICar,
	IDriver,
	IParam,
	IParams,
	setAddOrderFile,
	setAuthError,
	setAuthStatus,
	setCar,
	setCars,
	setDeleteOrderFile,
	setDriver,
	setDrivers,
	setItemState,
	setMessage,
	setMessageBox,
	setOrder,
	setOrderFileParam,
	setOrders,
	setParams,
	TParam,
} from '../redux/dataSlice';

const GET_ORDERS = 'GET_ORDERS';
const GET_CARS = 'GET_CARS';
const GET_DRIVERS = 'GET_DRIVERS';
const GET_ORDER = 'GET_ORDER';
const GET_CAR = 'GET_CAR';
const GET_DRIVER = 'GET_DRIVER';
const CHANGE_ORDER = 'CHANGE_ORDER';
const CHANGE_CAR = 'CHANGE_CAR';
const CHANGE_DRIVER = 'CHANGE_DRIVER';
const DELETE_CAR = 'DELETE_CAR';
const DELETE_DRIVER = 'DELETE_DRIVER';
const DOWNLOAD_ORDER_FILE = 'DOWNLOAD_ORDER_FILE';
const DELETE_ORDER_FILE = 'DELETE_ORDER_FILE';
const UPLOAD_ORDER_FILE = 'UPLOAD_ORDER_FILE';
const SEND_MESSAGE = 'SEND_MESSAGE';
const GET_MESSAGES = 'GET_MESSAGES';
const AUTH_BY_DATA = 'AUTH_BY_DATA';
const AUTH_BY_TOKEN = 'AUTH_BY_TOKEN';

export const getOrders = createAction(GET_ORDERS);
export const getCars = createAction(GET_CARS);
export const getDrivers = createAction(GET_DRIVERS);
export const getOrder = createAction<string>(GET_ORDER);
export const getCar = createAction<string>(GET_CAR);
export const getDriver = createAction<string>(GET_DRIVER);
export const changeOrder = createAction<IApiOrderChangeParams>(CHANGE_ORDER);
export const changeCar = createAction<ICar>(CHANGE_CAR);
export const changeDriver = createAction<IDriver>(CHANGE_DRIVER);
export const deleteCar = createAction<string>(DELETE_CAR);
export const deleteDriver = createAction<string>(DELETE_DRIVER);
export const downloadOrderFile = createAction<IApiOrderFileParams>(DOWNLOAD_ORDER_FILE);
export const deleteOrderFile = createAction<IApiOrderFileParams>(DELETE_ORDER_FILE);
export const uploadOrderFile = createAction<IApiOrderFileParams>(UPLOAD_ORDER_FILE);
export const sendMessage = createAction<{ orderId: string; message: string }>(SEND_MESSAGE);
export const getMessages = createAction<string>(GET_MESSAGES);
export const authByData = createAction<IAuthData>(AUTH_BY_DATA);
export const authByToken = createAction<string>(AUTH_BY_TOKEN);

function* updateParams(param: TParam | TParam[]) {
	const params: IParams[] = yield getParams(param);
	yield put(setParams(params));
	return params;
}
const paramMapping = (params: IParams[], type: TParam, id: string | undefined) =>
	params.find((i) => i.type === type)?.data?.find((i) => i.id === id)?.name || '!not found!';

function* getOrderWorker(action: PayloadAction<string>) {
	yield put(setOrder(undefined));
	const id = action.payload;
	const data: IApiOrderResponse = yield axios(orderApiConfig(id))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		const params: IParams[] = yield updateParams(['Terminal', 'Driver', 'Car', 'FileTypes']);
		yield put(
			setOrder({
				id,
				number: data.Number,
				date: data.Date,
				status: data.Status,
				dateP: data.DateP,
				dateV: data.DateV,
				numberKTK: data.NumberKTK,
				typeKTK: data.TypeKTK,
				address: data.Address,
				terminalPId: data.TerminalP,
				terminalPNumber: paramMapping(params, 'Terminal', data.TerminalP),
				terminalPAddress: data.TerminalPAddress,
				terminalVId: data.TerminalV,
				terminalVNumber: paramMapping(params, 'Terminal', data.TerminalV),
				terminalVAddress: data.TerminalVAddress,
				fare: data.Fare,
				driverId: data.Driver,
				driverName: paramMapping(params, 'Driver', data.Driver),
				carId: data.Car,
				carNumber: paramMapping(params, 'Car', data.Car),
				weight: data.Weight,
				comment: data.Comment,
				files: (data.Files || []).map((i) => ({
					id: i.ID,
					name: i.Name,
					type: i.Type,
					editable: i.Editable,
					extension: i.Extension,
					typeId: i.TypeID,
					typeName: paramMapping(params, 'FileTypes', i.TypeID),
				})),
			})
		);
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
	}
}

function* changeOrderWorker(action: PayloadAction<IApiOrderChangeParams>) {
	const data: IApiResponse = yield axios(changeOrderApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(getOrder(action.payload.orderId));
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка сохранения', description: data.Error }));
	}
}

function* getCarWorker(action: PayloadAction<string>) {
	yield put(setItemState({ itemName: 'car', state: 'loading' }));
	const id = action.payload;
	if (id) {
		const data: IApiCarResponse = yield axios(carApiConfig(id))
			.then((res) => res.data)
			.catch((e) => e);
		if (data.Success) {
			yield put(
				setCar({
					state: 'loaded',

					id,
					name: data.Name,
					grz: data.GRZ,
					model: data.Marka,
					pGrz: data.PGRZ,
					pModel: data.PMarka,
				})
			);
		} else {
			yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
		}
	} else {
		yield put(
			setCar({
				state: 'new',

				id: '',
				name: 'Новый автомобиль',
			})
		);
	}
}

function* changeCarWorker(action: PayloadAction<ICar>) {
	const data: IApiResponse = yield axios(changeCarApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(getCar(data.ID || ''));
		if (action.payload.state === 'new') {
			yield put(setItemState({ itemName: 'car', state: 'created' }));
		}
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка сохранения', description: data.Error }));
	}
}

function* deleteCarWorker(action: PayloadAction<string>) {
	const data: IApiResponse = yield axios(deleteCarApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(setItemState({ itemName: 'car', state: 'deleted' }));
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка удаления', description: data.Error }));
	}
}

function* getDriverWorker(action: PayloadAction<string>) {
	yield put(setItemState({ itemName: 'driver', state: 'loading' }));
	const id = action.payload;
	if (id) {
		const data: IApiDriverResponse = yield axios(driverApiConfig(id))
			.then((res) => res.data)
			.catch((e) => e);
		if (data.Success) {
			yield put(
				setDriver({
					state: 'loaded',

					id,
					name: data.Fio,
					fullName: data.Fio,
					surname: data.Familia,
					firstName: data.Imya,
					patronymic: data.Otch,
					birthDay: data.DateR,
					dlDate: data.VUDate,
					dlNumber: data.VUNomer,
					dlSeries: data.VUSeria,
					passCodeOVD: data.PassCodeOVD,
					passDate: data.PassDate,
					passNumber: data.PassNomer,
					passOVD: data.PassOVD,
					passSeries: data.PassSeria,
				})
			);
		} else {
			yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
		}
	} else {
		yield put(
			setDriver({
				state: 'new',

				id: '',
				name: 'Новый водитель',
			})
		);
	}
}

function* changeDriverWorker(action: PayloadAction<IDriver>) {
	const data: IApiResponse = yield axios(changeDriverApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(getDriver(data.ID || ''));
		if (action.payload.state === 'new') {
			yield put(setItemState({ itemName: 'driver', state: 'created' }));
		}
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка сохранения', description: data.Error }));
	}
}

function* deleteDriverWorker(action: PayloadAction<string>) {
	const data: IApiResponse = yield axios(deleteDriverApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(setItemState({ itemName: 'driver', state: 'deleted' }));
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка удаления', description: data.Error }));
	}
}

function* getOrdersWorker() {
	yield put(setOrders(undefined));
	const data: IApiOrdersResponse = yield axios(ordersApiConfig())
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success && data.orders && Array.isArray(data.orders)) {
		yield put(
			setOrders(
				data.orders.map((i) => ({
					id: i.ID || '',
					status: i.Status,
					number: i.Number,
					date: i.Date,
					dateP: i.DateP,
					terminal: i.Terminal,
					weight: i.Weight,
					fare: i.Fare,
					city: i.City,
				}))
			)
		);
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
	}
}

function* getCarsWorker() {
	yield put(setCars(undefined));
	const data: IApiCarsResponse = yield axios(carsApiConfig())
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success && data.Cars && Array.isArray(data.Cars)) {
		yield put(
			setCars(
				data.Cars.map((i) => ({
					id: i.ID,
					name: i.name,
				}))
			)
		);
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
	}
}

function* getDriversWorker() {
	yield put(setDrivers(undefined));
	const data: IApiDriversResponse = yield axios(driversApiConfig())
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success && data.Drivers && Array.isArray(data.Drivers)) {
		yield put(
			setDrivers(
				data.Drivers.map((i) => ({
					id: i.ID,
					name: i.name,
				}))
			)
		);
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
	}
}

function* downloadOrderFileWorker(action: PayloadAction<IApiOrderFileParams>) {
	const { orderId, fileId } = action.payload;
	const fileName = action.payload.name || fileId;
	yield put(setOrderFileParam({ id: fileId, param: 'isDownloading', value: true }));
	const data: IApiOrderFileDownloadResponse = yield axios(orderFileDownloadApiConfig({ orderId, fileId }))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success && data.Data) {
		const a = document.createElement('a');
		a.href = `data:application/octet-stream;base64,${data.Data}`;
		a.download = fileName;
		a.click();
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
	}
	yield put(setOrderFileParam({ id: fileId, param: 'isDownloading', value: false }));
}

function* deleteOrderFileWorker(action: PayloadAction<IApiOrderFileParams>) {
	const { orderId, fileId } = action.payload;
	yield put(setOrderFileParam({ id: fileId, param: 'isDeleting', value: true }));
	const data: IApiResponse = yield axios(orderFileDeleteApiConfig({ orderId, fileId }))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(setDeleteOrderFile(fileId));
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка удаления', description: data.Error }));
	}
	yield put(setOrderFileParam({ id: fileId, param: 'isDeleting', value: false }));
}

function* uploadOrderFileWorker(action: PayloadAction<IApiOrderFileParams>) {
	const { ext, name, fileId, typeId, typeName } = action.payload;
	yield put(
		setAddOrderFile({
			editable: 'true',
			extension: ext || '',
			name: name || '',
			id: fileId,
			type: typeName || '',
			typeId: typeId || '',
			isUploading: 'true',
		})
	);
	const data: IApiResponse = yield axios(orderFileUploadApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(setOrderFileParam({ id: fileId, param: 'isUploading', value: false }));
		yield put(setOrderFileParam({ id: fileId, param: 'id', value: data.ID }));
	} else {
		yield put(setOrderFileParam({ id: fileId, param: 'isUploading', value: true }));
		yield put(setMessage({ type: 'error', message: 'Ошибка загрузки', description: data.Error }));
	}
}

async function getParams(param: TParam | TParam[]): Promise<IParams[]> {
	const params: TParam[] = Array.isArray(param) ? param : [param];
	const result = [];
	for (let i = 0; i < params.length; i++) {
		const param = params[i];
		const response: IApiParamsResponse = await axios(paramsApiConfig(param))
			.then((res) => res.data)
			.catch((e) => e);
		const raw = response.Success
			? param === 'Car'
				? response.Cars
				: param === 'Driver'
				? response.Drivers
				: response.Params
			: undefined;
		const data = Array.isArray(raw) ? raw.map((i): IParam => ({ id: i.ID, name: i.name })) : undefined;
		result.push({ type: param, data });
	}
	return result;
}

function* sendMessageWorker(action: PayloadAction<{ orderId: string; message: string }>) {
	const { orderId, message } = action.payload;
	const data: IApiResponse = yield axios(sendMessageApiConfig(orderId, message))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(getMessages(orderId));
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка отправки сообщения', description: data.Error }));
	}
}
function* getMessagesWorker(action: PayloadAction<string>) {
	const data: IApiGetMessagesResponse = yield axios(getMessagesApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success) {
		yield put(setMessageBox(data.Message || ''));
	} else {
		yield put(setMessage({ type: 'error', message: 'Ошибка загрузки сообщений', description: data.Error }));
	}
}

function* authByDataWorker(action: PayloadAction<IAuthData>) {
	yield put(setAuthStatus('inprogress'));
	const data: IApiAuthResponse = yield axios(authByDataApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success && data.ApiKey) {
		yield put(setAuthStatus('authorized'));
		yield setToken(data.ApiKey);
	} else {
		yield put(setAuthStatus('error'));
		yield put(setAuthError(data.Error || 'Ошибка авторизации'));
	}
}

function* authByTokenWorker(action: PayloadAction<string>) {
	yield put(setAuthStatus('authorized'));
	yield put(setAuthStatus('inprogress'));
	const data: IApiAuthResponse = yield axios(authByTokenApiConfig(action.payload))
		.then((res) => res.data)
		.catch((e) => e);
	if (data.Success && data.ApiKey) {
		yield put(setAuthStatus('authorized'));
	} else {
		yield put(setAuthStatus('unauthorized'));
		yield removeToken();
		yield put(setMessage({ type: 'error', message: 'Ошибка авторизации', description: data.Error }));
	}
}

export function* getDataWatcher() {
	yield takeLatest(GET_ORDERS, getOrdersWorker);
	yield takeLatest(GET_CARS, getCarsWorker);
	yield takeLatest(GET_DRIVERS, getDriversWorker);
	yield takeLatest(GET_ORDER, getOrderWorker);
	yield takeLatest(GET_CAR, getCarWorker);
	yield takeLatest(GET_DRIVER, getDriverWorker);
	yield takeLatest(CHANGE_ORDER, changeOrderWorker);
	yield takeLatest(CHANGE_CAR, changeCarWorker);
	yield takeLatest(CHANGE_DRIVER, changeDriverWorker);
	yield takeLatest(DELETE_CAR, deleteCarWorker);
	yield takeLatest(DELETE_DRIVER, deleteDriverWorker);
	yield takeLatest(DOWNLOAD_ORDER_FILE, downloadOrderFileWorker);
	yield takeLatest(DELETE_ORDER_FILE, deleteOrderFileWorker);
	yield takeEvery(UPLOAD_ORDER_FILE, uploadOrderFileWorker);
	yield takeLatest(SEND_MESSAGE, sendMessageWorker);
	yield takeLatest(GET_MESSAGES, getMessagesWorker);
	yield takeLatest(AUTH_BY_DATA, authByDataWorker);
	yield takeLatest(AUTH_BY_TOKEN, authByTokenWorker);
}
