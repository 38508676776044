import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { IFile } from '../../redux/dataSlice';
import { deleteOrderFile } from '../../saga/getDataSaga';

function DeleteButton({ orderId, file }: { orderId: string; file: IFile }) {
	const dispatch = useDispatch();

	return (
		<Button
			size="small"
			disabled={file.editable === 'false' || file.isDeleting === 'true'}
			onClick={() =>
				dispatch(
					deleteOrderFile({
						orderId,
						fileId: file.id,
					})
				)
			}
		>
			{file.isDeleting === 'true' ? <LoadingOutlined spin /> : <DeleteOutlined />}
		</Button>
	);
}

export default DeleteButton;
