import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../redux/store';

function PrivateRoute({ component }: { component?: any }) {
	const status = useSelector((state: RootState) => state.data.auth.status);
	return status === 'unauthorized' ? <Navigate to="/auth" /> : component;
}

export default PrivateRoute;
