import { MessageOutlined } from '@ant-design/icons';
import { Button, Drawer, FloatButton, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getMessages, sendMessage } from '../../saga/getDataSaga';

const getFormatMessageBox = (message: string | undefined) => message;

function MessageBox() {
	const [open, setOpen] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');
	const order = useSelector((state: RootState) => state.data.order);
	const messageBox = useSelector((state: RootState) => state.data.messageBox);
	const dispatch = useDispatch();

	useEffect(() => {
		if (order && order.id !== 'new') dispatch(getMessages(order.id));
	}, [dispatch, order?.id]);

	const onClose = useCallback(() => setOpen(false), []);
	const sendMessageHandler = useCallback(() => {
		dispatch(sendMessage({ orderId: order?.id || '', message }));
		setMessage('');
	}, [dispatch, order?.id, message]);

	return (
		<>
			<FloatButton
				icon={<MessageOutlined />}
				type="primary"
				style={{ width: '50px', height: '50px' }}
				onClick={() => setOpen(true)}
			/>
			<Drawer title="Чат" placement="right" onClose={onClose} open={open}>
				<div className="messagebox">
					<TextArea value={getFormatMessageBox(messageBox)} className="text" />
					<Input placeholder="Сообщение" value={message} onChange={(e) => setMessage(e.target.value)} />
					<Button type="primary" className="sbutton" onClick={sendMessageHandler}>
						Отправить сообщение
					</Button>
				</div>
			</Drawer>
		</>
	);
}

export default MessageBox;
