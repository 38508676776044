import { ICar } from '../../redux/dataSlice';
import { changeCar, deleteCar, getCar } from '../../saga/getDataSaga';
import { IPageFormItem } from '../ui/PageForm';
import ItemPage from './ItemPage';

function Car() {
	return (
		<ItemPage
			itemName="car"
			path="cars"
			title="Машины"
			subTitle="Машина"
			getItem={getCar}
			changeItem={changeCar}
			deleteItem={deleteCar}
			formItems={
				[
					{ label: 'Название', name: 'name', disabled: true },
					{ label: 'Госномер', name: 'grz' },
					{ label: 'Марка', name: 'model' },
					{ label: 'Госномер прицепа', name: 'pGrz' },
					{ label: 'Марка прицепа', name: 'pModel' },
				] as (IPageFormItem<ICar> | false | undefined)[]
			}
		/>
	);
}

export default Car;
