import { CarOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function Main() {
	return (
		<div className="main page">
			<Link to="orders" className="button">
				<UnorderedListOutlined />
				<span className="text">Заявки</span>
			</Link>

			<Link to="drivers" className="button">
				<UserOutlined />
				<span className="text">Мои водители</span>
			</Link>

			<Link to="cars" className="button">
				<CarOutlined />
				<span className="text">Мой транспорт</span>
			</Link>
		</div>
	);
}

export default Main;
