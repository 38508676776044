import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { IFile } from '../../redux/dataSlice';
import { downloadOrderFile } from '../../saga/getDataSaga';

function DownloadButton({ orderId, file }: { orderId: string; file: IFile }) {
	const dispatch = useDispatch();

	return (
		<Button
			size="small"
			onClick={() =>
				dispatch(
					downloadOrderFile({
						orderId,
						fileId: file.id,
						name: `${file.name}.${file.extension}`,
					})
				)
			}
		>
			{file.isDownloading === 'true' ? <LoadingOutlined spin /> : <DownloadOutlined />}
		</Button>
	);
}

export default DownloadButton;
