import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

function Messages() {
	const message = useSelector((state: RootState) => state.data.message);
	const [notificationApi, contextHolder] = notification.useNotification();

	useEffect(() => {
		if (message) {
			const args: ArgsProps = { message: message.message, description: message.description, placement: 'bottom' };
			switch (message.type) {
				case 'warning':
					notificationApi.warning(args);
					break;
				case 'error':
					notificationApi.error(args);
					break;
				case 'success':
					notificationApi.success(args);
					break;
			}
		}
	}, [message]);
	return contextHolder;
}

export default Messages;
