import { Button, Form, Input, Typography } from 'antd';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { authByData } from '../../saga/getDataSaga';
import { Navigate } from 'react-router-dom';
import { IAuthData } from '../../redux/dataSlice';
const { Title, Text } = Typography;

function Auth() {
	const auth = useSelector((state: RootState) => state.data.auth);
	const dispatch = useDispatch();

	const onFinish = useCallback((data: IAuthData) => {
		dispatch(authByData(data));
	}, []);

	if (auth.status === 'authorized') {
		return <Navigate to="/" />;
	}

	return (
		<div className="center">
			<div className="page auth">
				<Title level={3}>Авторизация</Title>
				<Form
					name="auth"
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}
					className="form"
					onFinish={onFinish}
					autoComplete="on"
				>
					<Form.Item<IAuthData>
						label="Логин"
						name="login"
						rules={[{ required: true, message: 'Укажите логин!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item<IAuthData>
						label="Пароль"
						name="password"
						rules={[{ required: true, message: 'Укажите пароль!' }]}
					>
						<Input.Password />
					</Form.Item>
					{auth.status === 'error' && (
						<>
							<Text type="danger">{auth.error}</Text>
						</>
					)}

					<Form.Item>
						<Button className="sbutton" type="primary" htmlType="submit">
							Вход
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
}

export default Auth;
