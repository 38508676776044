import { Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { IOrder } from '../../redux/dataSlice';
const { Text } = Typography;

const formatDate = (date: string) => date.replaceAll('-', '.').replace('T', '   ');

function OrderCard({ data }: { data: IOrder }) {
	return (
		<Link to={`/orders/${data.id}`} style={{ textDecoration: 'none' }}>
			<Card
				title={
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<Text>{data.number}</Text>
						<Text keyboard>{data.status}</Text>
					</div>
				}
				bordered={false}
			>
				<div>
					<Text strong>Город:</Text>
					<Text> {data.city}</Text>
				</div>
				<div>
					<Text strong>Терминал:</Text>
					<Text> {data.terminal}</Text>
				</div>
				<div>
					<Text strong>Дата:</Text>
					<Text> {formatDate(data.date)}</Text>
				</div>
				<div>
					<Text strong>Ставка:</Text>
					<Text> {formatDate(data.fare)}</Text>
				</div>
			</Card>
		</Link>
	);
}

export default OrderCard;
