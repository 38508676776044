import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import ConfigProvider from 'antd/es/config-provider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<ConfigProvider
		theme={{
			token: {
				colorPrimary: getComputedStyle(document.body).getPropertyValue('--color-primary'),
				colorBgContainer: getComputedStyle(document.body).getPropertyValue('--color-container'),
			},
		}}
	>
		<Provider store={store}>
			<App />
		</Provider>
	</ConfigProvider>
);
