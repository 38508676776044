import { Button, Divider, Form, FormInstance, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';

export interface IPageFormItem<T> {
	label?: string;
	name?: keyof T;
	disabled?: boolean;
	params?: any;
	type?: 'input' | 'textarea';
	item?: 'item' | 'divider' | 'button' | 'element';
	content?: React.ReactElement<any>;
	onClick?: () => any;
}
export interface IPageFormData<T> {
	src: T;
	items: (IPageFormItem<T> | false | undefined)[];
}

function PageForm({ data, formRef }: { data: IPageFormData<any>; formRef: React.Ref<FormInstance> }) {
	const { src, items } = data;
	return (
		<Form
			name="form"
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 24 }}
			className="form"
			initialValues={src}
			ref={formRef}
			autoComplete="off"
		>
			{items.map((i, idx) => {
				if (i) {
					const type = i.type || 'input';
					const item = i.item || 'item';
					return (
						<Form.Item<any> key={idx} {...(item === 'item' ? { label: i.label, name: i.name } : {})}>
							{item === 'item' && (
								<>
									{type === 'input' && <Input disabled={i.disabled} {...i.params} />}
									{type === 'textarea' && <TextArea disabled={i.disabled} {...i.params} />}
								</>
							)}
							{item === 'button' && (
								<Button
									key={i.label}
									className="sbutton"
									type="primary"
									onClick={i.onClick}
									{...i.params}
								>
									{i.label}
								</Button>
							)}
							{item === 'divider' && <Divider />}
							{item === 'element' && i.content}
						</Form.Item>
					);
				}
				return null;
			})}
		</Form>
	);
}

export default PageForm;
