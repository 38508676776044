import { Button, Checkbox, FormInstance, Modal } from 'antd';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setItemState } from '../../redux/dataSlice';
import { RootState } from '../../redux/store';
import BreadCrumb from '../ui/BreadCrumb';
import PageForm, { IPageFormData, IPageFormItem } from '../ui/PageForm';
import Spiner from '../ui/Spiner';

export interface IItemPageParams<T> {
	itemName: 'car' | 'driver';
	path: string;
	title: string;
	subTitle: string;
	getItem: any;
	changeItem: any;
	deleteItem: any;
	formItems: (IPageFormItem<T> | false | undefined)[];
}

function ItemPage(params: IItemPageParams<any>) {
	const { itemName, path, title, subTitle, getItem, changeItem, deleteItem, formItems } = params;
	const [agreementModal, setAgreementModal] = useState<boolean>(false);
	const [agree, setAgree] = useState<boolean>(false);
	const { id } = useParams();
	const item = useSelector((state: RootState) => state.data[itemName]);
	const formRef = useRef<FormInstance>(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (item.state === 'deleted') {
			dispatch(setItemState({ itemName, state: 'empty' }));
			navigate(`/${path}`);
		} else if (item.state === 'created') {
			dispatch(setItemState({ itemName, state: 'loaded' }));
			navigate(`/${path}/${item.id}`);
		} else {
			if (id === 'new' && item.state !== 'new') dispatch(getItem(''));
			if (id !== 'new' && id !== item.id) dispatch(getItem(id || ''));
		}
	}, [dispatch, id, item]);

	useEffect(() => {
		formRef.current?.resetFields();
	}, [item, formRef]);

	const agreementModalOkHandler = useCallback(() => {
		const data = formRef.current?.getFieldsValue(true);
		dispatch(changeItem(data));
		setAgreementModal(false);
	}, [dispatch, formRef]);

	const agreementModalCancelHandler = useCallback(() => {
		setAgreementModal(false);
	}, []);

	const toChangeHandler = useCallback(() => {
		setAgreementModal(true);
	}, []);

	const toDeleteHandler = useCallback(() => {
		const data = formRef.current?.getFieldsValue(true);
		if (id && id !== 'new') {
			dispatch(deleteItem(id));
		}
	}, [dispatch]);

	return (
		<div className="page">
			<BreadCrumb
				items={[
					{
						path: `/${path}`,
						title: <span>{title}</span>,
					},
					{
						path: `/${path}/${id}`,
						title: <span>{`${subTitle}${item?.name ? ` (${item.name})` : ''}`}</span>,
					},
				]}
			/>
			{item.state === 'loading' || item.state === 'empty' ? (
				<Spiner tip="Загрузка" />
			) : (
				<>
					<PageForm
						formRef={formRef}
						data={
							{
								src: item,
								items: [
									...formItems,
									{ item: 'divider' },
									{
										item: 'button',
										label: 'Сбросить изменения',
										params: { type: 'default' },
										onClick: () => formRef.current?.resetFields(),
									},
									{
										item: 'button',
										label: 'Записать',
										onClick: toChangeHandler,
									},
									id &&
										id !== 'new' && { item: 'button', label: 'Удалить', onClick: toDeleteHandler },
								],
							} as IPageFormData<any>
						}
					/>
					<Modal
						open={agreementModal}
						onCancel={agreementModalCancelHandler}
						onOk={agreementModalOkHandler}
						footer={[
							<Button key="cancel" onClick={agreementModalCancelHandler}>
								Отмена
							</Button>,
							<Button key="select" type="primary" disabled={!agree} onClick={agreementModalOkHandler}>
								Записать
							</Button>,
						]}
					>
						<Checkbox onChange={(e) => setAgree(e.target.checked)}>
							Подтверждаю что персональные данные третьих лиц передаются с их согласия
						</Checkbox>
					</Modal>
				</>
			)}
		</div>
	);
}

export default ItemPage;
