import { IDriver } from '../../redux/dataSlice';
import { changeDriver, deleteDriver, getDriver } from '../../saga/getDataSaga';
import { IPageFormItem } from '../ui/PageForm';
import ItemPage from './ItemPage';

function Driver() {
	return (
		<ItemPage
			itemName="driver"
			path="drivers"
			title="Водители"
			subTitle="Водитель"
			getItem={getDriver}
			changeItem={changeDriver}
			deleteItem={deleteDriver}
			formItems={
				[
					{ label: 'Фамилия', name: 'surname' },
					{ label: 'Имя', name: 'firstName' },
					{ label: 'Отчество', name: 'patronymic' },
					{ label: 'Дата рождения', name: 'birthDay' },
					{ item: 'divider' },
					{ label: 'ВУ (Серия)', name: 'dlSeries' },
					{ label: 'ВУ (Номер)', name: 'dlNumber' },
					{ label: 'ВУ (Дата выдачи)', name: 'dlDate' },
					{ item: 'divider' },
					{ label: 'Паспорт (Серия)', name: 'passSeries' },
					{ label: 'Паспорт (Номер)', name: 'passNumber' },
					{ label: 'Паспорт (Дата выдачи)', name: 'passDate' },
					{ label: 'Паспорт (Кем выдан)', name: 'passOVD' },
					{ label: 'Паспорт (Код подразделения)', name: 'passCodeOVD' },
				] as (IPageFormItem<IDriver> | false | undefined)[]
			}
		/>
	);
}

export default Driver;
