import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

const itemRender = (route: any) => <Link to={route.path}>{route.title}</Link>;

function BreadCrumb({ items }: { items: any[] }) {
	return (
		<Breadcrumb
			itemRender={itemRender}
			items={[
				{
					path: '/',
					title: <HomeOutlined />,
				},
			].concat(items)}
		/>
	);
}

export default BreadCrumb;
