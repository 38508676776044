import { Spin } from 'antd';

function Spiner({ tip }: { tip?: string }) {
	return (
		<Spin tip={tip} size="large">
			<div style={{ padding: '50px', background: 'rgba(0, 0, 0, 0.05)', borderRadius: '4px' }} />
		</Spin>
	);
}

export default Spiner;
