import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IFile {
	id: string;
	name: string;
	extension: string;
	type: string;
	typeId: string;
	typeName?: string;
	editable: string;
	isDownloading?: string;
	isDeleting?: string;
	isUploading?: string;
	hasUploadError?: string;
}
export type TParam = 'City' | 'Terminal' | 'Status' | 'Car' | 'Driver' | 'FileTypes';
export interface IParam {
	id: string;
	name: string;
}
export interface IParams {
	type: TParam;
	data?: IParam[];
}
export type TOrderStatus = 'Торг' | 'Выиграна' | 'В работе' | 'Завершена';
export interface IOrder {
	id: string;
	status: TOrderStatus;
	number: string;
	date: string;
	dateP: string;
	terminal?: string;
	weight: string;
	fare: string;
	city?: string;

	address?: string;
	dateV?: string;
	numberKTK?: string;
	typeKTK?: string;
	terminalPId?: string;
	terminalPAddress?: string;
	terminalPNumber?: string;
	terminalVId?: string;
	terminalVAddress?: string;
	terminalVNumber?: string;
	driverId?: string;
	driverName?: string;
	carId?: string;
	carNumber?: string;
	comment?: string;
	files?: IFile[];
	uploadingFile?: boolean;
}
export interface IAuthData {
	login: string;
	password: string;
}
export type TAuthStatus = 'init' | 'authorized' | 'unauthorized' | 'inprogress' | 'error';
export interface IAuth {
	error?: string;
	status: TAuthStatus;
}
export type TItemState = 'empty' | 'loading' | 'loaded' | 'new' | 'deleted' | 'created';
export interface ICar {
	id?: string;
	state?: TItemState;
	name?: string;

	grz?: string;
	model?: string;
	pGrz?: string;
	pModel?: string;
}
export interface IDriver {
	id?: string;
	state?: TItemState;
	name?: string;

	fullName?: string;
	surname?: string;
	firstName?: string;
	patronymic?: string;
	birthDay?: string;
	passSeries?: string;
	passNumber?: string;
	passDate?: string;
	passOVD?: string;
	passCodeOVD?: string;
	dlSeries?: string;
	dlNumber?: string;
	dlDate?: string;
}
export interface IMessage {
	type: 'warning' | 'error' | 'success';
	message: string;
	description?: string;
}
export interface IDataState {
	orders?: IOrder[];
	cars?: ICar[];
	car: ICar;
	drivers?: IDriver[];
	driver: IDriver;
	order?: IOrder;
	params: IParams[];
	auth: IAuth;
	message?: IMessage;
	messageBox?: string;
}

const initialState: IDataState = {
	auth: {
		status: 'init',
	},
	params: [],
	car: { state: 'empty' },
	driver: { state: 'empty' },
};

export const dataSlice = createSlice({
	name: 'data',
	initialState,
	reducers: {
		setOrders: (state, action: PayloadAction<IOrder[] | undefined>) => {
			state.orders = action.payload;
		},
		setCars: (state, action: PayloadAction<ICar[] | undefined>) => {
			state.cars = action.payload;
		},
		setDrivers: (state, action: PayloadAction<IDriver[] | undefined>) => {
			state.drivers = action.payload;
		},
		setOrder: (state, action: PayloadAction<IOrder | undefined>) => {
			state.order = action.payload;
		},
		setCar: (state, action: PayloadAction<ICar>) => {
			state.car = action.payload;
		},
		setDriver: (state, action: PayloadAction<IDriver>) => {
			state.driver = action.payload;
		},
		setMessage: (state, action: PayloadAction<IMessage>) => {
			state.message = action.payload;
		},
		setMessageBox: (state, action: PayloadAction<string>) => {
			state.messageBox = action.payload;
		},

		setItemState: (state, action: PayloadAction<{ itemName: 'car' | 'driver'; state: TItemState }>) => {
			const item = state[action.payload.itemName];
			if (item) item.state = action.payload.state;
		},
		setParams: (state, action: PayloadAction<IParams[]>) => {
			const paramsList = action.payload;
			for (let i = 0; i < paramsList.length; i++) {
				const param = paramsList[i];
				const type = param.type;
				const data = param.data;

				const params = state.params.find((i) => i.type === type);
				if (params) {
					params.data = param.data;
				} else {
					state.params.push({ type, data });
				}
			}
		},
		setAuthError: (state, action: PayloadAction<string>) => {
			state.auth.error = action.payload;
		},
		setAuthStatus: (state, action: PayloadAction<TAuthStatus>) => {
			state.auth.status = action.payload;
		},
		setOrderFileParam: (
			state,
			action: PayloadAction<{ id: string; param: keyof IFile; value?: string | boolean }>
		) => {
			const { id, param, value } = action.payload;
			if (state.order && state.order.files) {
				const file = state.order.files.find((i) => i.id === id);
				if (file) file[param] = `${value}`;
			}
		},
		setAddOrderFile: (state, action: PayloadAction<IFile>) => {
			state.order?.files?.push(action.payload);
		},
		setDeleteOrderFile: (state, action: PayloadAction<string>) => {
			if (state.order && state.order.files) {
				const id = action.payload;
				const idx = state.order.files.findIndex((i) => i.id === id);
				if (idx > -1) {
					state.order.files.splice(idx, 1);
				}
			}
		},
	},
});

export const {
	setOrder,
	setCar,
	setDriver,
	setItemState,
	setOrders,
	setCars,
	setDrivers,
	setAuthError,
	setAuthStatus,
	setParams,
	setOrderFileParam,
	setDeleteOrderFile,
	setAddOrderFile,
	setMessage,
	setMessageBox,
} = dataSlice.actions;

export default dataSlice.reducer;
